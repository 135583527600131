<template>
  <!--begin::Invoice Report Listing-->
  <ListingTemplate
    custom-class="report-listing project-report-min-height"
    v-if="getPermission('report:view')"
  >
    <template v-slot:body>
      <ListingHeader>
        <template v-slot:content>
          <v-col cols="4" class="pt-0">
            <h1 class="form-title d-flex margin-auto">Invoice Report</h1>
          </v-col>
          <v-col cols="8" class="pt-0 text-right d-flex">
            <v-text-field
              dense
              filled
              color="cyan"
              label="Search..."
              solo
              flat
              v-model="search"
              clearable
              v-on:click:clear="clearFilter('search')"
            ></v-text-field>
            <Datepicker
              v-model="startDate"
              nullable
              solo
              custom-class="mx-4"
              placeholder="Start Date"
              :max-date="endDate"
              v-on:click:clear="clearFilter('start_date')"
              clearable
            ></Datepicker>
            <Datepicker
              v-model="endDate"
              nullable
              solo
              placeholder="End Date"
              :min-date="startDate"
              v-on:click:clear="clearFilter('end_date')"
              clearable
            ></Datepicker>
            <v-btn
              v-on:click="getRows()"
              color="cyan"
              class="mx-4 white--text custom-grey-border custom-bold-button"
              depressed
            >
              Search
            </v-btn>
            <v-btn
              v-on:click="exportRows()"
              class="custom-grey-border custom-bold-button white--text"
              color="cyan"
              depressed
            >
              Export
            </v-btn>
          </v-col>
        </template>
      </ListingHeader>
      <v-container fluid>
        <div class="row m-0">
          <div
            class="col mr-4 d-flex align-items-center mb-9 bg-light-warning rounded p-2"
          >
            <!--begin::Icon-->
            <span class="svg-icon svg-icon-warning mr-5">
              <span class="svg-icon svg-icon-4x">
                <!--begin::Svg Icon | path:/metronic/theme/html/demo2/dist/assets/media/svg/icons/Shopping/Dollar.svg-->
                <inline-svg
                  :src="$assetURL('media/svg/icons/Shopping/Dollar.svg')"
                />
                <!--end::Svg Icon-->
              </span>
            </span>
            <!--end::Icon-->
            <!--begin::Title-->
            <div class="d-flex flex-column flex-grow-1 mr-2">
              <p class="font-weight-bolder text-warning font-size-lg mb-1 m-0">
                Invoiced
              </p>
              <span class="font-weight-bolder text-warning py-1 font-size-h4">{{
                formatMoney(stats.invoiced)
              }}</span>
            </div>
            <!--end::Title-->
          </div>
          <div
            class="col mx-4 d-flex align-items-center mb-9 bg-light-secondary rounded p-2"
          >
            <!--begin::Icon-->
            <span class="svg-icon svg-icon-secondary mr-5">
              <span class="svg-icon svg-icon-4x">
                <!--begin::Svg Icon | path:/metronic/theme/html/demo2/dist/assets/media/svg/icons/Shopping/Dollar.svg-->
                <inline-svg
                  :src="$assetURL('media/svg/icons/Shopping/Dollar.svg')"
                />
                <!--end::Svg Icon-->
              </span>
            </span>
            <!--end::Icon-->
            <!--begin::Title-->
            <div class="d-flex flex-column flex-grow-1 mr-2">
              <p
                class="font-weight-bolder text-secondary font-size-lg mb-1 m-0"
              >
                Invoice Paid
              </p>
              <span
                class="font-weight-bolder text-secondary py-1 font-size-h4"
                >{{ formatMoney(stats.invoice_paid) }}</span
              >
            </div>
            <!--end::Title-->
          </div>
          <div
            class="col ml-4 d-flex align-items-center mb-9 bg-light-info rounded p-2"
          >
            <!--begin::Icon-->
            <span class="svg-icon svg-icon-info mr-5">
              <span class="svg-icon svg-icon-4x">
                <!--begin::Svg Icon | path:/metronic/theme/html/demo2/dist/assets/media/svg/icons/Shopping/Dollar.svg-->
                <inline-svg
                  :src="$assetURL('media/svg/icons/Shopping/Dollar.svg')"
                />
                <!--end::Svg Icon-->
              </span>
            </span>
            <!--end::Icon-->
            <!--begin::Title-->
            <div class="d-flex flex-column flex-grow-1 mr-2">
              <p class="font-weight-bolder text-info font-size-lg mb-1 m-0">
                Invoice Un Paid
              </p>
              <span class="font-weight-bolder text-info py-1 font-size-h4">{{
                formatMoney(stats.invoice_unpaid)
              }}</span>
            </div>
            <!--end::Title-->
          </div>
          <div
            class="col mx-4 d-flex align-items-center mb-9 bg-light-danger rounded p-2"
          >
            <!--begin::Icon-->
            <span class="svg-icon svg-icon-danger mr-5">
              <span class="svg-icon svg-icon-4x">
                <!--begin::Svg Icon | path:/metronic/theme/html/demo2/dist/assets/media/svg/icons/Shopping/Dollar.svg-->
                <inline-svg
                  :src="$assetURL('media/svg/icons/Shopping/Dollar.svg')"
                />
                <!--end::Svg Icon-->
              </span>
            </span>
            <!--end::Icon-->
            <!--begin::Title-->
            <div class="d-flex flex-column flex-grow-1 mr-2">
              <p class="font-weight-bolder text-danger font-size-lg mb-1 m-0">
                Invoice Cancelled
              </p>
              <span class="font-weight-bolder text-danger py-1 font-size-h4">{{
                formatMoney(stats.invoice_cancelled)
              }}</span>
            </div>
            <!--end::Title-->
          </div>
        </div>
        <b-table
          :items="rowData"
          :busy="dataLoading"
          :fields="fields"
          thead-tr-class="btx-table-tr"
          sticky-header
          responsive
          show-empty
          v-on:sort-changed="sortChanged"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle mx-4"></b-spinner>
              <span class="font-size-17 font-weight-600">Loading...</span>
            </div>
          </template>
          <template #cell(project_barcode)="row">
            <Barcode
              v-if="row.item.project_barcode"
              :barcode="row.item.project_barcode"
            ></Barcode>
            <em v-else class="text--secondary">No Project</em>
          </template>
          <template #cell(project_title)="row">
            <template v-if="row.item.project_title">{{
              row.item.project_title
            }}</template>
            <em v-else class="text--secondary">No Project</em>
          </template>
          <template #cell(customer_barcode)="row">
            <Barcode :barcode="row.item.customer_barcode"></Barcode>
          </template>
          <template #cell(quote_title)="row">
            <template v-if="row.item.quote_title">{{
              row.item.quote_title
            }}</template>
            <em v-else class="text--secondary">No Quotation</em>
          </template>
          <template #cell(quote_barcode)="row">
            <Barcode
              v-if="row.item.quote_barcode"
              :barcode="row.item.quote_barcode"
            ></Barcode>
            <em v-else class="text--secondary">No Quotation</em>
          </template>
          <template #cell(invoice_barcode)="row">
            <Barcode :barcode="row.item.invoice_barcode"></Barcode>
          </template>
          <template #cell(customer)="row">
            {{ formatMoney(row.item.quote_amount) }}
          </template>
          <template #cell(project_amount)="row">
            <template v-if="row.item.project_amount">{{
              formatMoney(row.item.project_amount)
            }}</template>
            <em v-else class="text--secondary">No Project</em>
          </template>
          <template #cell(quote_amount)="row">
            <template v-if="row.item.quote_amount">{{
              formatMoney(row.item.quote_amount)
            }}</template>
            <em v-else class="text--secondary">No Quotation</em>
          </template>
          <template #cell(invoiced_amount)="row">
            {{ formatMoney(row.item.invoiced_amount) }}
          </template>
          <template #cell(paid_amount)="row">
            {{ formatMoney(row.item.paid_amount) }}
          </template>
          <template #cell(quote_accepted_amount)="row">
            <template v-if="row.item.quote_accepted_amount">{{
              formatMoney(row.item.quote_accepted_amount)
            }}</template>
            <em v-else class="text--secondary">No Quotation</em>
          </template>
          <template #cell(quote_accepted_profit)="row">
            <template v-if="row.item.quote_accepted_profit">{{
              formatMoney(row.item.quote_accepted_profit)
            }}</template>
            <em v-else class="text--secondary">No Quotation</em>
          </template>
          <template #cell(unpaid_amount)="row">
            {{ formatMoney(row.item.unpaid_amount) }}
          </template>
          <template #cell(date_time)="row">
            <v-chip
              class="mx-2 my-1 custom-grey-border"
              color="blue-grey darken-1"
              text-color="white"
            >
              <v-avatar left>
                <v-icon>mdi-clock-outline</v-icon>
              </v-avatar>
              <p class="m-0 custom-nowrap-ellipsis">
                {{ formatDateTime(row.item.date_time) }}
              </p>
            </v-chip>
          </template>
          <template #empty>
            <p
              class="m-0 row-not-found text-center font-weight-500 font-size-16"
            >
              <img
                :src="$assetURL('media/error/empty.png')"
                class="row-not-found-image"
              />
              Uhh... There are no invoice at the moment.
            </p>
          </template>
        </b-table>
        <ListingFooter
          :data-loading="dataLoading"
          :showing-from="showingFrom"
          :showing-to="showingTo"
          :total-rows="totalRows"
          :current-page="currentPage"
          :total-pages="totalPages"
        ></ListingFooter>
      </v-container>
    </template>
  </ListingTemplate>
  <!--end::Invoice Report Listing-->
</template>

<script>
import Barcode from "@/view/pages/partials/Barcode";
import Datepicker from "@/view/pages/partials/Datepicker";
import CommonMixin from "@/core/plugins/common-mixin";
import { QUERY, DOWNLOAD } from "@/core/services/store/request.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PaginationEventBus } from "@/core/lib/pagination.lib";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import { saveAs } from "file-saver";

export default {
  mixins: [CommonMixin],
  name: "report-invoice",
  data() {
    return {
      dataLoading: true,
      totalRows: 0,
      currentPage: 0,
      totalPages: 0,
      rowsOffset: 0,
      search: null,
      startDate: null,
      endDate: null,
      stats: {
        invoiced: null,
        invoice_paid: null,
        invoice_unpaid: null,
        invoice_cancelled: null,
      },
      fields: [
        {
          key: "project_barcode",
          label: "Project #",
          sortable: true,
          stickyColumn: true,
          thClass: "simple-table-th",
          tdClass: "simple-table-td white-space-nowrap",
        },
        {
          key: "project_title",
          label: "Project Title",
          sortable: false,
          thClass: "simple-table-th",
          tdClass: "simple-table-td white-space-nowrap",
        },
        {
          key: "project_amount",
          label: "Project Amount",
          sortable: true,
          thClass: "simple-table-th",
          tdClass: "simple-table-td white-space-nowrap",
        },
        {
          key: "customer_barcode",
          label: "Customer #",
          sortable: true,
          stickyColumn: true,
          thClass: "simple-table-th",
          tdClass: "simple-table-td white-space-nowrap",
        },
        {
          key: "customer_display_name",
          label: "Customer Name",
          sortable: false,
          thClass: "simple-table-th",
          tdClass: "simple-table-td white-space-nowrap",
        },
        {
          key: "invoice_barcode",
          label: "Invoice #",
          sortable: true,
          stickyColumn: true,
          thClass: "simple-table-th",
          tdClass: "simple-table-td white-space-nowrap",
        },
        {
          key: "invoice_title",
          label: "Invoice Title",
          sortable: false,
          thClass: "simple-table-th",
          tdClass: "simple-table-td white-space-nowrap",
        },
        {
          key: "invoiced_amount",
          label: "Amount",
          sortable: true,
          thClass: "simple-table-th",
          tdClass: "simple-table-td white-space-nowrap",
        },
        {
          key: "paid_amount",
          label: "Paid Amount",
          sortable: true,
          thClass: "simple-table-th",
          tdClass: "simple-table-td white-space-nowrap",
        },
        {
          key: "unpaid_amount",
          label: "Un Paid Amount",
          sortable: false,
          thClass: "simple-table-th",
          tdClass: "simple-table-td white-space-nowrap",
        },
        {
          key: "quote_barcode",
          label: "Quote #",
          sortable: true,
          stickyColumn: true,
          thClass: "simple-table-th",
          tdClass: "simple-table-td white-space-nowrap",
        },
        {
          key: "quote_title",
          label: "Quote Title",
          sortable: false,
          thClass: "simple-table-th",
          tdClass: "simple-table-td white-space-nowrap",
        },
        {
          key: "quote_amount",
          label: "Quote Amount",
          sortable: true,
          thClass: "simple-table-th",
          tdClass: "simple-table-td white-space-nowrap",
        },
        {
          key: "quote_accepted_amount",
          label: "Quote Accepted Amount",
          sortable: true,
          thClass: "simple-table-th",
          tdClass: "simple-table-td white-space-nowrap",
        },
        {
          key: "quote_accepted_profit",
          label: "Quote Accepted Profit Amount",
          sortable: true,
          thClass: "simple-table-th",
          tdClass: "simple-table-td white-space-nowrap",
        },
        {
          key: "date_time",
          label: "Date Time",
          sortable: true,
          thClass: "simple-table-th",
          tdClass: "simple-table-td white-space-nowrap",
        },
      ],
      rowData: [],
      sortBy: null,
      sortOrder: "desc",
    };
  },
  components: {
    Barcode,
    Datepicker,
    ListingFooter,
    ListingTemplate,
    ListingHeader,
  },
  methods: {
    clearFilter(type) {
      if (type == "start_date") {
        this.startDate = null;
      } else if (type == "end_date") {
        this.endDate = null;
      } else {
        this.search = null;
      }
      this.getRows();
    },
    sortChanged({ sortBy, sortDesc }) {
      this.sortBy = sortBy;
      this.sortOrder = sortDesc ? "desc" : "asc";
      this.getRows();
    },
    resetRows() {
      this.search = null;
      this.start_date = null;
      this.end_date = null;
      this.current_page = 1;
      this.getRows();
    },
    exportRows() {
      this.dataLoading = true;
      let fileName = new Date();
      this.$store
        .dispatch(DOWNLOAD, {
          url: "report/invoice",
          data: {
            sort_by: this.sortBy || null,
            sort_order: this.sortOrder || null,
            search: this.search || null,
            start_date: this.startDate || null,
            end_date: this.endDate || null,
            current_page: this.currentPage || null,
            export: 1,
          },
        })
        .then(({ data }) => {
          saveAs(data, "invoice-report-export-" + fileName.getTime() + ".xlsx");
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    getRows() {
      this.dataLoading = true;
      this.$store
        .dispatch(QUERY, {
          url: "report/invoice",
          data: {
            sort_by: this.sortBy || null,
            sort_order: this.sortOrder || null,
            search: this.search || null,
            start_date: this.startDate || null,
            end_date: this.endDate || null,
            current_page: this.currentPage || null,
          },
        })
        .then(({ data }) => {
          this.totalPages = Math.ceil(data.total_invoice / data.per_page);
          this.currentPage = data.current_page;
          this.rowsOffset = data.offset;
          this.rowData = data.rows;
          this.stats = data.stats || {};
          this.totalRows = data.total_invoice;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Report",
      },
      {
        title: "Invoice",
      },
    ]);

    this.getRows();

    PaginationEventBus.$on("update:pagination", (param) => {
      this.currentPage = param;
      this.getRows();
    });
  },
  computed: {
    showingFrom() {
      return this.lodash.isEmpty(this.rowData) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      return this.rowsOffset + (this.rowData ? this.rowData.length : 0);
    },
  },
};
</script>
